(function ($, window, document, undefined) {

  'use strict';

  $(function () {

    $('.mobile-nav').on('click', function () {
      $(this).toggleClass('open');
      $('.main-nav').toggleClass('open');
    });

    // Get the header
    var header = document.getElementById("header");

// Get the offset position of the navbar
    var sticky = header.offsetTop;

    function onScroll() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }

    window.onscroll = function () {
      onScroll();
    };



    $('button.next').on('click', function () {
      var current = $('.timeline .line ul li.active').next();
      $(".timeline .line ul li").not(current).removeClass('active');
      current.addClass('active');

      $(".timeline-content .text").each(function( index ) {
        $(this).fadeOut('slow');
        if(current.index() === index){
          $(this).fadeIn('slow');
        }
      });

      if(current.index() === 7) {
        $(this).prop("disabled",true);
      }

      if(current.index() > 0) {
        $('.prev').prop("disabled",false);
      }

      return false;
    });


    $('button.prev').on('click', function () {
      var current = $('.timeline .line ul li.active').prev();
      $(".timeline .line ul li").not(current).removeClass('active');
      current.addClass('active');

      $(".timeline-content .text").each(function( index ) {
        $(this).fadeOut('slow');
        if(current.index() === index){
          $(this).fadeIn('slow');
        }
      });

      if(current.index() === 0) {
        $(this).prop("disabled",true);
      }

      if(current.index() < 7) {
        $('.next').prop("disabled",false);
      }


      return false;
    });





  });

})(jQuery, window, document);
